import React from "react"
import Seo from "../components/seo"
import styled from "styled-components"
import Contacts from "../components/contacts"
import GreenCircle from "../icons/green-circle.svg"
import { Link } from "gatsby"
import { graphql } from "gatsby"
const Style = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  height: 100%;

  @media (max-width: 1128px) {
    width: 100%;
  }

  @media (max-width: 475px) {
    min-height: max-content;
    margin: 64px 0px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-container {
      margin: 40px 0px;

      @media (max-width: 1128px) {
        width: 100%;
      }

      @media (max-width: 475px) {
        margin: 32px 0px;
      }
      .title {
        width: 840px;
        height: 116px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 52px;
        line-height: 58px;
        text-align: center;
        color: #111111;
        flex: none;
        order: 0;
        flex-grow: 0;

        @media (max-width: 1128px) {
          width: 100%;
        }

        @media (max-width: 475px) {
          width: 308px;
          height: 72px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 300;
          font-size: 32px;
          line-height: 36px;
          color: #111111;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: auto;
          margin-bottom: 32px;
        }

        .bold {
          width: 840px;
          height: 116px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 300;
          font-size: 52px;
          line-height: 58px;
          text-align: center;
          color: #111111;
          flex: none;
          order: 0;
          flex-grow: 0;

          @media (max-width: 1128px) {
            width: 100%;
          }

          @media (max-width: 475px) {
            width: 308px;
            height: 72px;
            font-family: Work Sans;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 36px;
            color: #111111;
            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }
      }
    }
    .text {
      width: 636px;
      height: 30px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #222222;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 40px 0px;

      @media (max-width: 1128px) {
        width: 100%;
      }

      @media (max-width: 475px) {
        width: 311px;
        height: 28px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #222222;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 32px 0px;
      }
    }
    .overcontainer {
      margin: 8px 0;
      margin-right: auto;

      .position-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0px;
        width: 440px;
        height: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;

        @media (max-width: 475px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          width: fit-content;
          height: max-content;
          flex: none;
          order: 1;
          flex-grow: 0;
        }

        .position-one,
        .position-two {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          width: 100%;
          height: 30px;
          flex: none;
          flex-grow: 0;
          margin: 16px 0px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #222222;
          @media (max-width: 475px) {
            border: 1px solid red;
            height: 60px;
          }
        }

        .bold-position {
          width: fit-content;
          height: 30px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #222222;
          flex: none;
          flex-grow: 0;
          margin: 0px 12px;
          @media (max-width: 475px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            width: max-content;
            height: 30px;
            flex: none;
            flex-grow: 0;
            margin: 0;
          }
        }
      }

      .icon {
        display: inline-block;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 12px;
      }
    }
  }
`
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  span {
    width: 100%;
    display: flex;
    justify-content: row;
    flex-wrap: wrap;
  }
`

const Job = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #111111;

  align-items: center;
`

const JobTitle = styled.strong`
  /* width: max-content; */
`
const Position = styled.div`
  width: max-content;
`
const Location = styled.div`
  width: max-content;
`
const PostDate = styled.i`
  width: max-content;
`

function Careers({ data }) {
  var jobNumber = 0
  const { job } = data

  return (
    <>
      <Seo title="Careers Page" />
      <Style>
        <div className="container">
          <div className="title-container">
            <h1 className="title">
              Open Positions at{" "}
              <div className="bold">PDF Pro Software Ltd.</div>
            </h1>
          </div>

          {job.nodes.map((post, i) => {
            jobNumber += 1
            return (
              <div className="overcontainer">
                <Job to={`${post.uid}`}>
                  <GreenCircle className="icon" />
                  <TextBox>
                    <span>
                      <JobTitle>{post.data.title}</JobTitle>
                      <Position>- {post.data.position} –</Position>
                      <Location>{post.data.location}</Location>
                    </span>
                    <PostDate>posted on {post.data.date}</PostDate>
                  </TextBox>
                </Job>
              </div>
            )
          })}
          {jobNumber == 0 && (
            <span className="text">No Positions currently available.</span>
          )}
        </div>
      </Style>
      <Contacts></Contacts>
    </>
  )
}

export default Careers

export const query = graphql`
  query CareersQuery {
    job: allPrismicJob {
      nodes {
        data {
          date(formatString: "MMMM D, Y")
          location
          position
          title
        }
        uid
      }
    }
  }
`
